
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import VideoList from '@/components/VideoList.vue'

const reportingsAsset = 'reportings/'
export default defineComponent({
   emits: ['initFullScreen'],
  setup() {
    return {
      videoItems: [
        {
          title: 'C-roads - amélioration la sécurité et l\'expérience de nos clients',
          url: '685436216',
          image: reportingsAsset + 'reporting-1.jpg'
        },
        {
          title: 'Campagne sécurité routière - corridor sécurité',
          url: '584927805',
          image: reportingsAsset + 'reporting-2.jpg'
        },
        {
          title: 'Campagne sécurité routière - distracteurs au volant',
          url: '580712666',
          image: reportingsAsset + 'reporting-3.jpg'
        },
        {
          title: 'Campagne sécurité routière - interdistances de sécurité',
          url: '674338150',
          image: reportingsAsset + 'reporting-4.jpg'
        },
        {
          title: 'Campagne sécurité routière - adapter sa vitesse à la météo',
          url: '676207489',
          image: reportingsAsset + 'reporting-5.jpg'
        },
        {
          title: 'Campange sécurité routière - bien voir être vu.e',
          url: '679916597',
          image: reportingsAsset + 'reporting-6.jpg'
        },
        {
          title: 'Animation sur aire',
          url: '685432201',
          image: reportingsAsset + 'reporting-7.jpg'
        },
        {
          title: 'Animation sur aire espace canin',
          url: '685433356',
          image: reportingsAsset + 'reporting-8.jpg'
        },
        {
          title: 'Animation sur aire Hotel transylvania',
          url: '685434056',
          image: reportingsAsset + 'reporting-9.jpg'
        },
        {
          title: 'Animation sur aire - nos amis à quattre patte',
          url: '685434568',
          image: reportingsAsset + 'reporting-10.jpg'
        }
      ]
    }
  },
  components: {
    Header,
    VideoList
  }
})
