import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_video_list = _resolveComponent("video-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header, {
      "is-home": false,
      title: "Reportages",
      icon: "film"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_video_list, { list: _ctx.videoItems }, null, 8, ["list"])
    ])
  ], 64))
}